import React from 'react';
import Layout, { Content } from '../../components/Layout';
import Form from '../../components/forms/Flight';

const Flight = () => {
  return (
    <Layout title={'Flight'}>
      <Content style={{ marginLeft: '0', margin: '0 auto' }}>
        <Form />
      </Content>
    </Layout>
  );
};

export default Flight;