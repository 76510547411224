import React, { useState } from 'react';
import Form from './Form';
import ThankYou from './ThankYou';
import { DATA_MODELS } from './Form/Field';

const FORM_ID = 'flight';

const formConfig = [
  {
    label: 'Preferred Departure Airport',
    name: 'Departure_Airport',
    isRequired: true,
    model: DATA_MODELS.deal
  },
  {
    label: 'Return Date',
    name: 'Return_Date',
    isRequired: true,
    model: DATA_MODELS.deal
  },
  {
    label: 'Additional Requirements',
    name: 'Additional_Requirements',
    model: DATA_MODELS.deal
  },
  {
    label: 'I Agree to the Terms and Conditions',
    name: 'agreed to privacy',
    isRequired: true,
    model: DATA_MODELS.person
  },
];

const Flight = () => {
  const [submitted, setSubmited] = useState(false);
  const submit = () => {
    setSubmited(true);
  };

  if (submitted) {
    return <ThankYou />;
  }

  return (
    <Form
      headline="Flight"
      formId={FORM_ID}
      config={formConfig}
      initValues={null}
      onSubmit={submit}
    />
  );
};

export default Flight;